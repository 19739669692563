import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const DropdownOne = (props) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    //Tooltip.rebuild();
  });

  return (
    <div
      className="dropdown-one"
      onMouseLeave={() => {
        setExpanded(false);
      }}
    >
      <p
        className="drop-btn"
        tabIndex={0}
        onMouseEnter={() => {
          setExpanded(true);
        }}
      >
        {props.title}
      </p>
      <div className={"dropdown-content " + (expanded ? " expanded" : "")}>
        <div>
          {props.options.map((item, index) => {
            return item.isLink ? (
              <p data-tip={item.comingSoon ? "Coming soon!" : ""} key={index}>
                <a href={item.route} target="_blank" rel="noreferrer">
                  {item.text}
                </a>
              </p>
            ) : (
              <p
                data-tip={item.comingSoon ? "Coming soon!" : ""}
                key={index}
                onClick={() => {
                  navigate(item.route, { replace: true });
                }}
              >
                {item.text}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

DropdownOne.defaultProps = {
  title: "DROPDOWN",
  options: [{ text: "One", link: "https://256art.com" }],
};

DropdownOne.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default DropdownOne;
