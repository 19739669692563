import { useEffect } from "react";
import { Tooltip } from "react-tooltip";
import {
  useMetamask,
  useMetamaskUpdate,
} from "../../providers/MetamaskProvider";

const ConnectButton = () => {
  const connect = useMetamaskUpdate();
  const metamask = useMetamask();

  useEffect(() => {
    //Tooltip.rebuild();
  });

  return metamask.installed ? (
    <div
      data-tip={metamask.accounts.length > 0 ? metamask.accounts[0] : ""}
      className={
        "connect-button " +
        (metamask.connecting
          ? "connecting"
          : metamask.accounts.length > 0
            ? "connected"
            : "")
      }
      onClick={connect}
    >
      <p>
        {metamask.accounts.length > 0
          ? ((metamask.chain.toString() === "0x1" ||
            metamask.chain === 1 ||
            metamask.chain?.chainId === 1)) ||
            ((metamask.chain.toString() === "0x5" ||
              metamask.chain === 5 ||
              metamask.chain?.chainId === 5)) ||
            ((metamask.chain.toString() === "0x89" ||
              metamask.chain === 137 ||
              metamask.chain?.chainId === 137)) ||
            ((metamask.chain.toString() === "0xaa36a7" ||
              metamask.chain === 11155111 ||
              metamask.chain?.chainId === 11155111))
            ? metamask.accounts[0].substring(0, 10) + "..."
            : "Unsupported chain"
          : "Connect"}
        <span className={"status "}> ·</span>
      </p>
    </div>
  ) : (
    <div className="connect-button">
      <a href="https://metamask.io" target="_blank" rel="noreferrer">
        Install metamask
      </a>
    </div>
  );
};

export default ConnectButton;
