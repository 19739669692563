import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import { useMetamaskChangeChain } from "../../providers/MetamaskProvider";

const ChangeChainButton = (props) => {
    const [expanded, setExpanded] = useState(false);
    const changeChain = useMetamaskChangeChain();

    useEffect(() => {
        //Tooltip.rebuild();
    });

    // Function to call when an option is selected
    const handleSelect = (chainId) => {
        changeChain(chainId);
        setExpanded(false);
    }

    return (
        <div
            className="dropdown-one"
            onMouseLeave={() => {
                setExpanded(false);
            }}
        >
            <p
                className="drop-btn"
                tabIndex={0}
                onMouseEnter={() => {
                    setExpanded(true);
                }}
            >
                ⛓
            </p>
            <div className={"dropdown-content " + (expanded ? " expanded" : "")}>
                <div>
                    {props.options.map((item, index) => {
                        return (
                            <p
                                key={index}
                                onClick={() => {
                                    handleSelect(item.chainId);
                                }}
                            >
                                {item.text}
                            </p>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

ChangeChainButton.defaultProps = {
    options: [
        { text: "Mainnet", chainId: 1 },
        { text: "Goerli (testnet)", chainId: 5 },
    ],
};

ChangeChainButton.propTypes = {
    options: PropTypes.array.isRequired,
};

export default ChangeChainButton;
