import HamburgerOne from "../buttons/HamburgerOne";
import { useState } from "react";
import ExpandedMenu from "./ExpandedMenu";
import { Link } from "react-router-dom";
import twoFiveSixArt from "../../assets/256art.svg";

const HorizontalMenu = ({ toggleTheme, theme }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={"horizontal-menu "}>
      <ExpandedMenu
        expanded={expanded}
        closeMenu={() => {
          setExpanded(false);
        }}
        toggleTheme={toggleTheme}
        theme={theme}
      />
      <div
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <HamburgerOne />
      </div>
      <Link
        to="/"
        onClick={() => {
          setExpanded(false);
        }}
      >
        <div className="menu-title">
          <img src={twoFiveSixArt} alt="" />
        </div>
      </Link>
    </div>
  );
};

export default HorizontalMenu;
