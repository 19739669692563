import DropdownOne from "../inputs/DropdownOne";
import discord from "../../assets/discord-black.png";
import twitter from "../../assets/twitter-black.png";
import sun from "../../assets/sun.png";
import moon from "../../assets/moon.png";
import { Link } from "react-router-dom";
import IconButton from "../buttons/IconButton";
import ConnectButton from "../buttons/ConnectButton";
import twoFiveSixArt from "../../assets/256art.svg";
import ChangeChainButton from "../buttons/ChangeChainButton";
import { useMetamask } from "../../providers/MetamaskProvider";

const MainMenu = ({ toggleTheme, theme }) => {
  const metamask = useMetamask();
  return (
    <div
      id="main-menu"
      className={"main-menu "}
    >
      {((metamask.chain.toString() === "0x1" ||
        metamask.chain === 1 ||
        metamask.chain?.chainId === 1)) ? <></> : <div className="chain-message"><p>{`You are currently connected to ` + (((metamask.chain.toString() === "0x2105" ||
          metamask.chain === 8453 ||
          metamask.chain?.chainId === 8453)) ? `Base` : (((metamask.chain.toString() === "0x89" ||
            metamask.chain === 137 ||
            metamask.chain?.chainId === 137)) ? `Polygon` : (((metamask.chain.toString() === "0xa" ||
              metamask.chain === 10 ||
              metamask.chain?.chainId === 10))) ? `Optimism` : (((metamask.chain.toString() === "0xaa36a7" ||
                metamask.chain === 11155111 ||
                metamask.chain?.chainId === 11155111)) ? `Sepolia` : `an unsupported chain`)))}</p></div>}
      <div className="main-menu-inner">
        <Link to="/">
          <div className="menu-title">
            <img src={twoFiveSixArt} alt="" />
          </div>
        </Link>
        <div className="menu-items">
          <div className="theme-toggle" onClick={() => {
            toggleTheme();
          }}>
            <IconButton src={theme === "light" ? moon : sun} />
          </div>
          <Link as={"p"} to={"/projects"} className="menu-item">
            PROJECTS
          </Link>
          <Link as={"p"} to={"/artists"} className="menu-item">
            ARTISTS
          </Link>

          <Link as={"p"} to={"/gallery"} className="menu-item">
            GALLERY
          </Link>
          <Link as={"p"} to={"/portal"} className="menu-item">
            ARTIST PORTAL
          </Link>
          <DropdownOne
            title="LEARN MORE·"
            options={[
              {
                text: "Explore 256ART",
                comingSoon: false,
                route: "https://docs.256art.com/learn-more/understanding-and-navigating-256art/",
                isLink: true,
              },
              {
                text: "Artist docs",
                comingSoon: false,
                route: "https://docs.256art.com/artist-documentation/why-choose-256art/",
                isLink: true,
              },
              {
                text: "Terms of service",
                route: "terms",
                comingSoon: false,
                isLink: false,
              },
              {
                text: "Privacy policy",
                route: "privacy",
                comingSoon: false,
                isLink: false,
              },
            ]}
          />
          <div className="socials">
            <a
              href="https://discord.gg/VwUB89KKMu"
              target="_blank"
              rel="noreferrer"
            >
              <IconButton src={discord} />
            </a>
            <a
              href="https://twitter.com/@Mint256Art"
              target="_blank"
              rel="noreferrer"
            >
              <IconButton src={twitter} />
            </a>
          </div>
          <ConnectButton></ConnectButton>
          <ChangeChainButton></ChangeChainButton>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
