import { Link } from "react-router-dom";
import ConnectButton from "../buttons/ConnectButton";
const ExpandedMenu = (props) => {
  const { expanded, closeMenu, toggleTheme, theme } = props;
  return (
    <div className={"expanded-menu " + (expanded ? "expanded" : "")}>
      <div className="menu-items">
        <ConnectButton></ConnectButton>
        <Link
          as={"p"}
          to={"/projects"}
          className="menu-item"
          onClick={() => {
            closeMenu();
          }}
        >
          Projects
        </Link>
        <Link
          as={"p"}
          to={"/artists"}
          className="menu-item"
          onClick={() => {
            closeMenu();
          }}
        >
          Artists
        </Link>
        <Link
          as={"p"}
          to={"/portal"}
          className="menu-item"
          onClick={() => {
            closeMenu();
          }}
        >
          Artist Portal
        </Link>
        <Link
          as={"p"}
          to={"/gallery"}
          className="menu-item"
          onClick={() => {
            closeMenu();
          }}
        >
          Gallery
        </Link>
        <a href="https://docs.256art.com/learn-more/understanding-and-navigating-256art/" target="_blank" rel="noreferrer">
          Learn more
        </a>
        <a href="https://docs.256art.com/artist-documentation/why-choose-256art/" target="_blank" rel="noreferrer">
          Artist docs
        </a>
        <Link
          as={"p"}
          to={"/terms"}
          className="menu-item"
          onClick={() => {
            closeMenu();
          }}
        >
          Terms of service
        </Link>
        <Link
          as={"p"}
          to={"/privacy"}
          className="menu-item"
          onClick={() => {
            closeMenu();
          }}
        >
          Privacy policy
        </Link>
        <a
          href="https://discord.gg/VwUB89KKMu"
          target="_blank"
          rel="noreferrer"
        >
          Discord
        </a>
        <a
          href="https://twitter.com/@Mint256Art"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
        <p onClick={() => {
          toggleTheme();
        }}>
          {theme === "light" ? "Dark mode" : "Light mode"}
        </p>

        <p onClick={props.closeMenu}>⬅</p>
      </div>
    </div>
  );
};

export default ExpandedMenu;
